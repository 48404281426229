import React from 'react';
import { useReducer } from 'reinspect';

// import ContextProvider from '../hoc/ContextProvider';

import {
  TOGGLE_CART_DRAWER,
  ADD_ITEM,
  REMOVE_ITEM,
  CLEAR_CART,
} from '../actions/cart';

const initialState = {
  cartDrawerVisible: false,
  items: [],
  counter: 0,
};

export const CartContext = React.createContext({
  ...initialState,
  toggleCartDrawer: () => {},
  addItem: () => {},
  removeItem: () => {},
  clearCart: () => {},
  increment: () => {},
});

const reducer = (state, action) => {
  switch (action.type) {
  case TOGGLE_CART_DRAWER:
    return {
      ...state,
      cartDrawerVisible:
          typeof action.payload === 'boolean'
            ? action.payload
            : !state.cartDrawerVisible,
    };

  case ADD_ITEM: {
    const { id } = action.payload;
    const index = state.items.findIndex(item => item.id === id);
    const isInCart = index > -1;

    if (isInCart) {
      const newItem = { ...state.items[index] };
      newItem.quantity += 1;
      const newItems = [...state.items];
      newItems[index] = newItem;
      return { ...state, items: newItems };
    }

    const newItem = { ...action.payload, quantity: 1 };

    return { ...state, items: [...state.items, newItem] };
  }

  case REMOVE_ITEM: {
    const id = action.payload;
    const newItems = state.items.filter(item => item.id !== id);
    return { ...state, items: newItems };
  }

  case CLEAR_CART: {
    return { ...state, items: [] };
  }

  case 'INCREMENT': {
    return { ...state, counter: state.counter + 1 };
  }

  default:
    return state;
  }
};

const CartStore = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, 'Cart');

  const toggleCartDrawer = bool =>
    dispatch({ type: TOGGLE_CART_DRAWER, payload: bool });

  const addItem = item => dispatch({ type: ADD_ITEM, payload: item });

  const removeItem = id => dispatch({ type: REMOVE_ITEM, payload: id });

  const clearCart = () => dispatch({ type: CLEAR_CART });

  const increment = () => dispatch({ type: 'INCREMENT' });

  const { items, cartDrawerVisible, counter } = state;

  return (
    // <ContextProvider
    //   component={CartContext.Provider}
    //   value={state}
    //   toggleCartDrawer={toggleCartDrawer}
    //   addItem={addItem}
    //   removeItem={removeItem}
    //   clearCart={clearCart}
    // >
    //   {children}
    // </ContextProvider>
    <CartContext.Provider
      value={{
        cartDrawerVisible,
        items,
        counter,
        toggleCartDrawer,
        addItem,
        removeItem,
        clearCart,
        increment,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartStore;
