import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

import { layoutTypesNames } from '../config/constants';

const ProductCard = ({ product, onAddToCart, layout }) => {
  const isGrid = [
    layoutTypesNames.GRID_DEFAULT,
    layoutTypesNames.GRID_COMPACT,
  ].includes(layout);

  const isCompact = [
    layoutTypesNames.GRID_COMPACT,
    layoutTypesNames.LIST_COMPACT,
  ].includes(layout);

  const getItemSize = (side) => {
    switch (side) {
      case 'media':
        return {
          xs: isGrid ? 12 : isCompact ? 4 : 5,
          sm: isGrid ? 12 : isCompact ? 3 : 4,
          md: isGrid ? 12 : isCompact ? 2 : 3,
        };

      case 'content':
        return {
          xs: isGrid ? 12 : isCompact ? 8 : 7,
          sm: isGrid ? 12 : isCompact ? 9 : 8,
          md: isGrid ? 12 : isCompact ? 10 : 9,
        };

      default:
        break;
    }
  };

  return (
    <Card>
      <CardActionArea
        component={Link}
        to={{
          pathname: `/product/${product.id}`,
          state: product,
        }}
      >
        <Grid container spacing={8}>
          <Grid item {...getItemSize('media')}>
            <CardMedia
              title={product.name}
              style={{
                height: isGrid ? 260 : '100%',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
              image={product.thumbnail}
            />
          </Grid>
          <Grid item {...getItemSize('content')}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                {product.name}
              </Typography>

              <Typography variant="h6" gutterBottom>
                Price: ${product.price}
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Rating: {product.rating.toFixed(2)}
              </Typography>

              {!isCompact && (
                <Typography component="p" gutterBottom noWrap>
                  {product.excerpt}
                </Typography>
              )}

              {!isGrid && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onAddToCart(product);
                  }}
                >
                  Add to cart
                </Button>
              )}
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>

      {isGrid && (
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => onAddToCart(product)}
          >
            Add to cart
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

ProductCard.propTypes = {
  layout: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

const compareProps = (prevProps, nextProps) =>
  isEqual(prevProps.product, nextProps.product) &&
  isEqual(prevProps.layout, nextProps.layout);

export default React.memo(ProductCard, compareProps);
