import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import { sortingTypes } from '../config/constants';

const styles = {
  cardDivider: {
    marginTop: 6,
  },
};

const sortingOptions = [
  {
    value: '',
    label: 'Default',
  },
  {
    value: `name_${sortingTypes.ASC}`,
    label: 'Name (asc)',
  },
  {
    value: `name_${sortingTypes.DESC}`,
    label: 'Name (desc)',
  },
  {
    value: `price_${sortingTypes.ASC}`,
    label: 'Price (asc)',
  },
  {
    value: `price_${sortingTypes.DESC}`,
    label: 'Price (desc)',
  },
  {
    value: `rating_${sortingTypes.ASC}`,
    label: 'Rating (asc)',
  },
  {
    value: `rating_${sortingTypes.DESC}`,
    label: 'Rating (desc)',
  },
];

const ProductsSorting = ({
  classes,
  sorting,
  defaultExpanded,
  onSortingChange,
}) => {
  return (
    <Paper>
      <ExpansionPanel defaultExpanded={defaultExpanded}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Sorting</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <TextField
            id="sort"
            select
            label="Select"
            margin="normal"
            value={sorting}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => onSortingChange(e.target.value)}
          >
            {sortingOptions.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
};

ProductsSorting.propTypes = {
  classes: PropTypes.object.isRequired,
  sorting: PropTypes.string,
  defaultExpanded: PropTypes.bool.isRequired,
  onSortingChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductsSorting);
