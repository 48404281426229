import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableFooter from '@material-ui/core/TableFooter';

const countTotalQuantity = (data) =>
  data.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);

const countTotalPrice = (data) =>
  data.reduce((totalPrice, item) => totalPrice + item.price * item.quantity, 0);

const CartTable = ({ data, onDeleteItem }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell padding="dense">Name</TableCell>
        <TableCell align="right" padding="dense">
          Quantity
        </TableCell>
        <TableCell align="right" padding="dense">
          Price($)
        </TableCell>
        <TableCell align="right" padding="checkbox">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {!data.length && (
        <TableRow>
          <TableCell colSpan={4} align="center">
            Cart is empty.
          </TableCell>
        </TableRow>
      )}
      {data.map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row" padding="dense">
            {item.name}
          </TableCell>
          <TableCell align="right" padding="dense">
            {item.quantity}
          </TableCell>
          <TableCell align="right" padding="dense">
            {(item.quantity * item.price).toFixed(2)}
          </TableCell>
          <TableCell align="right" padding="checkbox">
            <IconButton
              size="small"
              aria-label="Delete"
              onClick={() => onDeleteItem(item.id)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>

    <TableFooter>
      <TableRow>
        <TableCell>Total</TableCell>

        <TableCell align="right" padding="dense">
          {countTotalQuantity(data)}
        </TableCell>

        <TableCell align="right" padding="dense">
          ${countTotalPrice(data)}
        </TableCell>
      </TableRow>
    </TableFooter>
  </Table>
);

CartTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export default CartTable;
