import React /* , { useContext } */ from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { layoutTypesNames, layoutButtons } from '../../config/constants';
// import { ProductsContext } from '../../store/productsStore';
// import { CartContext } from '../../store/cartStore';
// import { AuthDispatchContext, AuthStateContext } from '../../store/authStore';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    '&:not(:last-of-type)': {
      marginRight: theme.spacing.unit,
    },
  },
});

const ProductsLayout = ({ classes, layout, onChangeLayout }) => {
  // const authDispatchCtx = useContext(AuthDispatchContext);
  // const authStateCtx = useContext(AuthStateContext);

  // const productsCtx = useContext(ProductsContext);

  // const cartCtx = useContext(CartContext);

  const renderButton = name => {
    const IconComponent = layoutButtons[name].icon;

    return (
      <Button
        key={name}
        className={classes.button}
        color={layoutTypesNames[name] === layout ? 'secondary' : 'default'}
        onClick={() => onChangeLayout(layoutTypesNames[name])}
      >
        <IconComponent className={classes.leftIcon} />
        {layoutButtons[name].label}
      </Button>
    );
  };

  return (
    <Paper className={classes.root}>
      {Object.values(layoutTypesNames).map(name => renderButton(name))}
      {/* <div>
        <span>AuthCtx:</span>
        <button onClick={authDispatchCtx.increment}>+</button>
        <span>{authStateCtx.counter}</span>
      </div>

      <div>
        <span>OrdersCtx:</span>
        <button onClick={productsCtx.increment}>+</button>
        <span>{productsCtx.counter}</span>
      </div>

      <div>
        <span>CartCtx:</span>
        <button onClick={cartCtx.increment}>+</button>
        <span>{cartCtx.counter}</span>
      </div> */}
    </Paper>
  );
};

ProductsLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
  onChangeLayout: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductsLayout);
