import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import { Collapse, Divider, Paper } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { CartContext } from '../store/cartStore';
import { OrdersContext } from '../store/ordersStore';
import { AuthDispatchContext, AuthStateContext } from '../store/authStore';
import CartTable from './CartTable';
import AuthForm from './AuthForm';
import OrdersForm from './OrdersForm';
import PaymentForm from './PaymentForm';

const styles = (theme) => ({
  drawer: {
    zIndex: theme.zIndex.appBar - 100,
  },
  drawerContent: {
    width: 500,
    height: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    // flexGrow: 1,
    flex: '1 1 50%',
    paddingTop: theme.mixins.escapeFromAppBar,
    // paddingBottom: 100,
  },
  swipeableViews: {
    // flexGrow: 1,
    flex: '1 1 50%',
  },
  viewsContent: {
    height: '100%',
    // maxHeight: `calc(100vh - ${theme.mixins.escapeFromAppBar}px - 8px)`,
    padding: `${theme.spacing.unit * 3}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  orderTableWrapper: {
    flexGrow: 1,
    marginLeft: `-${theme.spacing.unit * 3}px`,
    marginRight: `-${theme.spacing.unit * 3}px`,
  },
  listItem: {
    padding: `${theme.spacing.unit * 2}px 0`,
  },
  total: {
    fontWeight: '700',
  },
});

const steps = ['Login', 'Shipping address', 'Payment details', 'Review order'];

const CartDrawer = ({ classes }) => {
  const cartCtx = useContext(CartContext);
  const ordersCtx = useContext(OrdersContext);
  const authDispatchCtx = useContext(AuthDispatchContext);
  const authStateCtx = useContext(AuthStateContext);

  const [register, setRegister] = useState(false);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState(null);

  const handleAuthenticate = async (data) => {
    const error = await authDispatchCtx.login(data);

    error ? setError(error) : setStep(step + 1);
  };

  const handleConfirmOrder = async () => {
    const products = cartCtx.items.reduce((ids, { id, quantity }) => {
      for (let i = 0; i < quantity; i++) {
        ids.push({ id, quantity });
      }
      return ids;
    }, []);

    const createdOrder = await ordersCtx.createOrder({ ...formData, products });

    if (createdOrder) {
      setStep(step + 1);
    }
  };

  const handleNextStep = (data) => {
    setFormData({ ...formData, ...data });

    setStep(step + 1);
  };

  const { user } = authStateCtx;

  return (
    <>
      <IconButton
        size="small"
        style={{ color: 'white' }}
        aria-label="Delete"
        onClick={() => cartCtx.toggleCartDrawer()}
      >
        <Badge badgeContent={cartCtx.items.length} color="secondary">
          <ShoppingCartIcon fontSize="default" />
        </Badge>
      </IconButton>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={cartCtx.cartDrawerVisible}
        onClose={() => {
          setStep(0);
          cartCtx.toggleCartDrawer(false);
        }}
      >
        <div
          className={classes.drawerContent}
          style={{
            height: '-webkit-fill-available',
          }}
        >
          <SwipeableViews
            containerStyle={{ height: '100%' }}
            className={classes.swipeableViews}
            index={step}
          >
            <div
              className={classes.viewsContent}
              style={{ height: '-webkit-fill-available' }}
            >
              <Typography variant="h4">Cart</Typography>

              <div className={classes.orderTableWrapper}>
                <CartTable
                  data={cartCtx.items}
                  onDeleteItem={(id) => cartCtx.removeItem(id)}
                />
              </div>

              <Grow in={!!cartCtx.items.length}>
                <Button
                  style={{ margin: '15px auto' }}
                  variant="contained"
                  color="primary"
                  onClick={() => setStep(step + 1)}
                >
                  Proceed to checkout
                </Button>
              </Grow>
            </div>

            <div
              className={classes.viewsContent}
              style={{ height: '-webkit-fill-available' }}
            >
              <Typography variant="h4">Login or register</Typography>
              {user ? (
                <>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ marginTop: 32 }}
                  >
                    Hello, {user.name}. You are logged in{' '}
                    <VerifiedUserIcon color="primary" fontSize="inherit" />
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    {user.email} {"It's not you?"}
                  </Typography>

                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ width: 150, marginTop: 16 }}
                    onClick={() => authDispatchCtx.logout()}
                  >
                    Logout
                  </Button>

                  <Grid container spacing={24} style={{ marginTop: 'auto' }}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => setStep(step - 1)}
                        fullWidth
                      >
                        Go back
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setStep(step + 1)}
                        fullWidth
                      >
                        Proceed
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <AuthForm
                    handleSubmit={handleAuthenticate}
                    handleSetRegister={(status) => setRegister(status)}
                    register={register}
                    error={error}
                    formStyles={{
                      flexGrow: '1',
                      flexDirection: 'column',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  />
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => setStep(step - 1)}
                    style={{ marginTop: 24 }}
                    fullWidth
                  >
                    Go back
                  </Button>
                </>
              )}
            </div>

            <div
              className={classes.viewsContent}
              style={{ height: '-webkit-fill-available' }}
            >
              <Typography variant="h4" gutterBottom>
                Shipping address
              </Typography>

              <OrdersForm
                insideDialog={false}
                handleSubmit={handleNextStep}
                handleCancel={() => setStep(step - 1)}
                cancelLabel="Go back"
                formStyles={{
                  flexGrow: '1',
                  flexDirection: 'column',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              />
            </div>

            <div
              className={classes.viewsContent}
              style={{ height: '-webkit-fill-available' }}
            >
              <Typography variant="h4" gutterBottom>
                Payment details
              </Typography>

              <PaymentForm
                // TODO Add payment info to order
                // handleSubmit={handleNextStep}
                handleSubmit={() => setStep(step + 1)}
                handleCancel={() => setStep(step - 1)}
                cancelLabel="Go back"
                formStyles={{
                  flexGrow: '1',
                  flexDirection: 'column',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              />
            </div>

            <div
              className={classes.viewsContent}
              style={{ height: '-webkit-fill-available' }}
            >
              <Typography variant="h4">Review order</Typography>

              <List disablePadding>
                {cartCtx.items.map((item) => (
                  <ListItem className={classes.listItem} key={item.name}>
                    <ListItemText
                      primary={item.name}
                      secondary={item.excerpt}
                    />
                    <Typography variant="body2">{item.price}</Typography>
                  </ListItem>
                ))}
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Total" />
                  <Typography variant="subtitle1" className={classes.total}>
                    {cartCtx.items.length &&
                      cartCtx.items.reduce(
                        (totalPrice, item) =>
                          totalPrice + item.price * item.quantity,
                        0
                      )}
                  </Typography>
                </ListItem>
              </List>

              <Grid container spacing={24} style={{ marginTop: 'auto' }}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => setStep(step - 1)}
                    fullWidth
                  >
                    Go back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmOrder}
                    fullWidth
                  >
                    Confirm order and pay
                  </Button>
                </Grid>
              </Grid>
            </div>

            <div
              className={classes.viewsContent}
              style={{ height: '-webkit-fill-available', textAlign: 'center' }}
            >
              <Typography variant="h4" gutterBottom>
                Your order has been confirmed!
              </Typography>

              <Typography variant="subtitle2" gutterBottom>
                You will get an email with tracking code shortly.
              </Typography>

              <Button
                style={{ margin: '15px auto' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  cartCtx.clearCart();
                  cartCtx.toggleCartDrawer(false);
                  setStep(0);
                }}
              >
                Okay! Close the drawer!
              </Button>
            </div>
          </SwipeableViews>

          <Collapse in={step > 0}>
            <Paper>
              <Stepper activeStep={step - 1} alternativeLabel>
                {steps.length &&
                  steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
              </Stepper>
            </Paper>
          </Collapse>

          {/* <MobileStepper
            steps={3}
            position="static"
            variant="progress"
            activeStep={step}
            className={classes.mobileStepper}
            nextButton={
              <Button
                size="small"
                onClick={() => setStep(step + 1)}
                disabled={step === 2}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={() => setStep(step - 1)}
                disabled={step === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          /> */}
        </div>
      </Drawer>
    </>
  );
};

CartDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CartDrawer);
