import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { AuthDispatchContext } from '../store/authStore';
import AuthForm from '../components/AuthForm';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
});

const Auth = ({ classes }) => {
  const [register, setRegister] = useState(false);
  const [error, setError] = useState('');

  const autDispatchhCtx = useContext(AuthDispatchContext);

  const submitHandler = async data => {
    const error = register
      ? await autDispatchhCtx.register(data)
      : await autDispatchhCtx.login(data);

    error && setError(error);
  };

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography component="h1" variant="h5">
        {register ? 'Register' : 'Sign in'}
      </Typography>

      <AuthForm
        register={register}
        handleSubmit={submitHandler}
        handleSetRegister={status => setRegister(status)}
        error={error}
      />
    </Paper>
  );
};

Auth.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Auth);
