import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import Checkbox from '@material-ui/core/Checkbox';
// import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  cardDivider: {
    marginTop: 6,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

const ProductsFilters = ({
  filters,
  defaultExpanded,
  onFilterChange,
  classes,
}) => {
  // const [open, setOpen] = useState(true);

  return (
    <Paper>
      <ExpansionPanel defaultExpanded={defaultExpanded}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Filters</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={16}>
            {Object.keys(filters)
              .filter((key) => filters[key].method === 'contains')
              .map((key) => (
                <Grid item xs={12} key={key}>
                  <TextField
                    label={key}
                    fullWidth
                    // TODO Add two way data binding that allows for delay
                    // value={filters[key].value}
                    defaultValue={filters[key].value}
                    onChange={({ target: { value } }) =>
                      onFilterChange(key, value)
                    }
                  />
                </Grid>
              ))}

            {Object.keys(filters)
              .filter((key) => filters[key].method === 'range')
              .map((key) => (
                <React.Fragment key={key}>
                  <Grid item xs={6}>
                    <TextField
                      label={`${key}Min`}
                      // TODO Add two way data binding that allows for delay
                      // value={filters[key].value.min || ''}
                      fullWidth
                      defaultValue={filters[key].value.min || ''}
                      onChange={({ target: { value } }) =>
                        onFilterChange(key, {
                          min: isNaN(parseInt(value)) ? null : parseInt(value),
                          max: filters[key].value.max,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label={`${key}Max`}
                      // TODO Add two way data binding that allows for delay
                      // value={filters[key].value.max || ''}
                      fullWidth
                      defaultValue={filters[key].value.max || ''}
                      onChange={({ target: { value } }) =>
                        onFilterChange(key, {
                          min: filters[key].value.min,
                          max: isNaN(parseInt(value)) ? null : parseInt(value),
                        })
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}

            {/* <ListItem button onClick={() => setOpen(!open)}>
          <ListItemText primary="filter group" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem> */}

            {/* <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText primary="filter items" />
              <ListItemSecondaryAction>
                <Checkbox
                  onChange={value => console.log({ value })}
                  checked={false}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse> */}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
};

ProductsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const compareProps = (prevProps, nextProps) =>
  isEqual(prevProps.filters, nextProps.filters);

export default withStyles(styles)(React.memo(ProductsFilters, compareProps));
