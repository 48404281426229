export const inRange = (x, min, max) =>
  (x - (min !== -Infinity ? min : x - 1)) *
    (x - (max !== Infinity ? max : x + 1)) <=
  0;

export const applyFilters = (product, filtersConfig) => {
  let passes = true;

  Object.keys(filtersConfig).forEach(filterName => {
    switch (filtersConfig[filterName].method) {
    case 'contains': {
      const formatted = filtersConfig[filterName].value.toLowerCase();

      if (!product[filterName].toLowerCase().includes(formatted)) {
        passes = false;
      }

      break;
    }

    case 'range': {
      if (
        !inRange(
          product[filterName],
          filtersConfig[filterName].value.min || 0,
          filtersConfig[filterName].value.max || 999999
        )
      ) {
        passes = false;
      }

      break;
    }

    default:
      break;
    }
  });

  return passes;
};
