import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const formConfig = [
  {
    placeholder: 'cardName',
    type: 'text',
    required: false,
    width: 6,
  },
  {
    placeholder: 'cardNumber',
    type: 'number',
    required: false,
    width: 6,
  },
  {
    placeholder: 'expiryDate',
    type: 'text',
    required: false,
    width: 6,
  },
  {
    placeholder: 'CVV',
    type: 'number',
    required: false,
    width: 6,
  },
];

const initialState = formConfig.reduce((acc, input) => {
  acc[input.placeholder] = '';
  return acc;
}, {});

const PaymentForm = ({
  handleSubmit,
  handleCancel,
  cancelLabel = 'Cancel',
  formStyles = null,
}) => {
  const [state, setState] = useState(initialState);

  const modifyState = (key, value) => {
    const newPair = { [key]: value };
    setState({ ...state, ...newPair });
  };

  return (
    <form
      style={formStyles}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(state);
      }}
    >
      <Grid container spacing={24}>
        {formConfig.map(el => (
          <Grid key={el.placeholder} item xs={12} sm={el.width}>
            <TextField
              margin="dense"
              id={el.placeholder}
              label={el.placeholder}
              type={el.type}
              style={{ textTransform: 'capitalize' }}
              value={state[el.placeholder] || ''}
              required={el.required}
              fullWidth
              onChange={({ target: { value } }) =>
                modifyState(el.placeholder, value)
              }
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid>
      </Grid>

      <Grid container spacing={24} style={{ marginTop: 30 }}>
        <Grid item xs={6}>
          <Button
            onClick={handleCancel}
            color="default"
            variant="contained"
            fullWidth
          >
            {cancelLabel}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button color="primary" type="submit" variant="contained" fullWidth>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

PaymentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  formStyles: PropTypes.object,
};

export default PaymentForm;
