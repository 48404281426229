import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';

import { prepareInitialFormState } from '../utils/forms';

const styles = {
  dialogContent: {
    paddingTop: '0 !important',
  },
};

const formConfig = [
  // {
  //   placeholder: 'price',
  //   type: 'number',
  //   required: true,
  // },
  {
    placeholder: 'firstName',
    type: 'text',
    required: false,
    width: 6,
  },
  {
    placeholder: 'lastName',
    type: 'text',
    required: false,
    width: 6,
  },
  {
    placeholder: 'street',
    type: 'text',
    required: false,
    width: 12,
  },
  {
    placeholder: 'number',
    type: 'number',
    required: false,
    width: 6,
  },
  {
    placeholder: 'postalCode',
    type: 'text',
    required: false,
    width: 6,
  },
  {
    placeholder: 'city',
    type: 'text',
    required: false,
    width: 6,
  },
  {
    placeholder: 'country',
    type: 'text',
    required: false,
    width: 6,
  },
];

const initialState = prepareInitialFormState(formConfig);

const OrdersForm = ({
  editing = null,
  insideDialog = true,
  formStyles = null,
  handleSubmit,
  handleCancel,
  cancelLabel = 'Cancel',
  classes,
}) => {
  const [state, setFormState] = useState(editing ? editing : initialState);

  const modifyFormState = (key, value) => {
    const newEntry = { [key]: value };
    setFormState({ ...state, ...newEntry });
  };

  const chooseDataType = (el, value) =>
    el.type === 'number' ? parseInt(value) : value;

  const renderFields = elements =>
    elements.map(el => (
      <Grid key={el.placeholder} item xs={12} sm={el.width}>
        <TextField
          margin="dense"
          id={el.placeholder}
          label={el.placeholder}
          type={el.type}
          style={{ textTransform: 'capitalize' }}
          value={state[el.placeholder] || ''}
          required={el.required}
          fullWidth
          onChange={({ target: { value } }) =>
            modifyFormState(el.placeholder, chooseDataType(el, value))
          }
        />
      </Grid>
    ));

  return (
    <>
      {insideDialog && (
        <DialogTitle id="form-dialog-title">
          {editing ? 'Edit' : 'Create'} Order
        </DialogTitle>
      )}

      <form
        style={formStyles}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(state);
        }}
      >
        {insideDialog ? (
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={24}>
              {renderFields(formConfig)}
            </Grid>
          </DialogContent>
        ) : (
          <Grid container spacing={24}>
            {renderFields(formConfig)}
          </Grid>
        )}

        {insideDialog ? (
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {cancelLabel}
            </Button>

            <Button color="primary" type="submit">
              Confirm
            </Button>
          </DialogActions>
        ) : (
          <Grid container spacing={24} style={{ marginTop: 30 }}>
            <Grid item xs={6}>
              <Button
                onClick={handleCancel}
                color="default"
                variant="contained"
                fullWidth
              >
                {cancelLabel}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

OrdersForm.propTypes = {
  editing: PropTypes.object,
  insideDialog: PropTypes.bool,
  formStyles: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(styles)(OrdersForm);
