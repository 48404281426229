import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewListIcon from '@material-ui/icons/ViewList';
import ListIcon from '@material-ui/icons/List';

export const sortingTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const layoutTypesNames = {
  GRID_DEFAULT: 'GRID_DEFAULT',
  GRID_COMPACT: 'GRID_COMPACT',
  LIST_DEFAULT: 'LIST_DEFAULT',
  LIST_COMPACT: 'LIST_COMPACT',
};

export const layoutButtons = {
  [layoutTypesNames.GRID_DEFAULT]: { icon: ViewCompactIcon, label: 'Grid' },
  [layoutTypesNames.GRID_COMPACT]: {
    icon: ViewComfyIcon,
    label: 'Grid Compact',
  },
  [layoutTypesNames.LIST_DEFAULT]: { icon: ViewListIcon, label: 'List' },
  [layoutTypesNames.LIST_COMPACT]: { icon: ListIcon, label: 'List Compact' },
};

export const layoutTypes = {
  [layoutTypesNames.GRID_DEFAULT]: { xs: 12, sm: 6, md: 4, xl: 3 },
  [layoutTypesNames.GRID_COMPACT]: { xs: 12, sm: 6, md: 3, xl: 2 },
  [layoutTypesNames.LIST_DEFAULT]: { xs: 12 },
  [layoutTypesNames.LIST_COMPACT]: { xs: 12 },
};
