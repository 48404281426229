import cloneDeep from 'lodash/cloneDeep';

export const generateSearchParams = (filters, sorting) =>
  Object.entries(filters)
    .map(filter => {
      const [filterName, filterData] = filter;
      if (typeof filterData.value !== 'object') {
        return filterData.value.length
          ? `${filterName}=${filterData.value}`
          : '';
      }

      return [
        filterData.value.min ? `${filterName}.min=${filterData.value.min}` : '',
        filterData.value.max ? `${filterName}.max=${filterData.value.max}` : '',
      ]
        .filter(Boolean)
        .join('&');
    })
    .concat(`${sorting ? `sorting=${sorting}` : ''}`)
    .filter(Boolean)
    .join('&');

export const decodeSearchParams = search =>
  JSON.parse(
    `{"${decodeURI(search.substring(1))
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`
  );

export const constructFiltersFromSearch = (decoded, defaultFilters) => {
  const copiedFilters = cloneDeep(defaultFilters);

  Object.entries(decoded).map(entry => {
    const [name, val] = entry;

    const shallowName = name.substring(0, name.indexOf('.'));
    const nestedName = name.substring(name.indexOf('.') + 1, name.length);

    if (name.includes('.')) {
      return (copiedFilters[shallowName]['value'][nestedName] = val);
    }

    return (copiedFilters[name].value = val);
  });

  return copiedFilters;
};
