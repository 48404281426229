import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

import OrdersTable from '../components/OrdersTable';
import { OrdersContext } from '../store/ordersStore';
import OrdersForm from '../components/OrdersForm';
import OrderPreview from '../components/OrderPreview';

const Orders = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editing, setEditing] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const ordersCtx = useContext(OrdersContext);

  useEffect(() => {
    ordersCtx.fetchOrders();
  }, []);

  useEffect(() => {
    const { id } = props.match.params;

    if (id) {
      const order = ordersCtx.orders.find((o) => o.id === +id);
      setSelectedOrder(order);
    }
  }, [props.location, ordersCtx.orders]);

  const handleSubmit = (formData) => {
    editing
      ? ordersCtx.editOrder(editing.id, formData)
      : ordersCtx.createOrder(formData);
    setModalVisible(false);
  };

  const handleSelectOrder = (id) => {
    selectedOrder && selectedOrder.id === id
      ? props.history.replace(`/orders/${id}`)
      : props.history.push(`/orders/${id}`);
  };

  const handleDeleteOrder = async (id) => {
    await ordersCtx.deleteOrder(id);
  };

  const openEditDialog = (id) => {
    const orderToEdit = ordersCtx.orders.find((order) => order.id === id);
    setEditing(orderToEdit);
    setModalVisible(true);
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Orders
      </Typography>

      <Divider variant="inset" style={{ margin: '20px 0' }} />

      <Grid container spacing={24}>
        <Grid item xs={12} md={6}>
          <OrdersTable
            data={ordersCtx.orders}
            onSelectOrder={(order) => handleSelectOrder(order)}
            onEditOrder={(id) => openEditDialog(id)}
            onDeleteOrder={(id) => handleDeleteOrder(id)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Fade in={!!selectedOrder}>
            <OrderPreview order={selectedOrder} />
          </Fade>
        </Grid>
      </Grid>

      <Dialog
        open={modalVisible}
        scroll="body"
        onClose={() => setModalVisible(false)}
        aria-labelledby="form-dialog-title"
      >
        <OrdersForm
          editing={editing}
          handleSubmit={(formData) => handleSubmit(formData)}
          handleCancel={() => setModalVisible(false)}
        />
      </Dialog>
    </div>
  );
};

Orders.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Orders);
