import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

const orderInfo = ['id', 'status', 'price', 'createdAt'];

const styles = {
  cardDivider: {
    marginTop: 6,
  },
};

const addressInfo = [
  'firstName',
  'lastName',
  'street',
  'number',
  'postalCode',
  'city',
  'country',
];

const OrderPreview = ({ order, classes, ...transitionStyles }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12} sm={6}>
        <Paper {...transitionStyles}>
          {order && (
            <List>
              <ListSubheader>Order information</ListSubheader>

              <Divider className={classes.cardDivider} />

              {orderInfo.map(key => (
                <ListItem key={key}>
                  <ListItemText primary={key} secondary={order[key]} />
                </ListItem>
              ))}

              <Divider className={classes.cardDivider} />

              <List component="div">
                <ListSubheader>Products</ListSubheader>

                <Divider className={classes.cardDivider} />

                {order.products.map(({ product, quantity }) => (
                  <ListItem
                    component={Link}
                    key={product.id}
                    button
                    to={{
                      pathname: `/product/${product.id}`,
                      state: product,
                    }}
                  >
                    <ListItemText primary={product.name} />
                    <Typography variant="body2" style={{ marginRight: 8 }}>
                      ({quantity})
                    </Typography>
                    <Typography variant="body2">
                      ${product.price * quantity}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </List>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper>
          {order && (
            <List>
              <ListSubheader>Address</ListSubheader>

              <Divider style={{ marginTop: 6 }} />

              {addressInfo.map(key => (
                <ListItem key={key}>
                  <ListItemText primary={key} secondary={order[key]} />
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

OrderPreview.propTypes = {
  order: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderPreview);
