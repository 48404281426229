import ProductDetails from '../views/ProductDetails';
import Products from '../views/Products';
import Orders from '../views/Orders';
import User from '../views/User';
import Auth from '../views/Auth';

export const routes = [
  {
    path: '/auth',
    component: Auth,
    privateRoute: false,
  },
  {
    path: '/user',
    component: User,
    linkText: 'User',
    privateRoute: true,
  },
  {
    path: '/orders/:id',
    component: Orders,
    privateRoute: true,
  },
  {
    path: '/orders',
    component: Orders,
    linkText: 'Orders',
    privateRoute: true,
  },
  {
    path: '/product/:id',
    component: ProductDetails,
    privateRoute: false,
  },
  {
    path: '/',
    component: Products,
    linkText: 'Products',
    privateRoute: false,
  },
];
