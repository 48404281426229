import React, { useContext } from 'react';
import axios from 'axios';
import to from 'await-to-js';
import { useState } from 'reinspect';

// import ContextProvider from '../hoc/ContextProvider';
import { AuthDispatchContext } from './authStore';
import { PRODUCTS_ENDPOINT } from '../config/api';

const initialState = {
  products: [],
};

export const ProductsContext = React.createContext({
  ...initialState,
  counter: 0,
  fetchProducts: () => {},
  fetchProduct: () => {},
  increment: () => {},
});

const ProductsStore = ({ children }) => {
  const [products, setProducts] = useState(initialState, 'Products');

  const [counter, setCounter] = useState(0);

  const authDispatchCtx = useContext(AuthDispatchContext);

  const fetchProducts = async () => {
    const [error, products] = await to(
      axios.get(PRODUCTS_ENDPOINT).then(res => res.data)
    );

    if (error) {
      return authDispatchCtx.handleError(error, 'fetchProducts');
    }

    products.map(
      product =>
        (product.thumbnail =
          product.thumbnail ||
          'https://media.playstation.com/is/image/SCEA/ps4-whats-new-icon-01-us-30nov17?$native_nt$')
    );

    setProducts(products);
  };

  const fetchProduct = async id => {
    const [error, product] = await to(
      axios.get(`${PRODUCTS_ENDPOINT}/${id}`).then(res => res.data)
    );

    if (error) {
      return authDispatchCtx.handleError(error, 'fetchProduct');
    }

    return product;
  };

  const increment = () => setCounter(counter + 1);

  return (
    // <ContextProvider
    //   component={ProductsContext.Provider}
    //   value={products}
    //   fetchProduct={fetchProduct}
    //   fetchProducts={fetchProducts}
    // >
    //   {children}
    // </ContextProvider>
    <ProductsContext.Provider
      value={{
        products,
        counter,
        fetchProducts,
        fetchProduct,
        increment,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsStore;
