import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import {
  generateSearchParams,
  decodeSearchParams,
  constructFiltersFromSearch,
} from '../utils/queryParams';
import { applyFilters } from '../utils/filter';
import { applySorting } from '../utils/sort';
import { CartContext } from '../store/cartStore';
import { ProductsContext } from '../store/productsStore';
import { layoutTypesNames, layoutTypes } from '../config/constants';
import ProductsFilters from '../components/ProductsFilters';
// import log from '../utils/consoleColor';
import ProductCard from '../components/ProductCard';
import ProductsSorting from '../components/ProductsSorting';
import ProductsLayoutSettings from '../components/ProductsLayoutSettings/ProductsLayoutSettings';
// import ProductListItem from '../components/ProductListItem';

const defaultFilters = {
  name: {
    method: 'contains',
    value: '',
  },
  excerpt: {
    method: 'contains',
    value: '',
  },
  price: {
    method: 'range',
    value: {
      min: null,
      max: null,
    },
  },
};

const defaultSorting = '';

const Products = (props) => {
  const cartCtx = useContext(CartContext);
  const productsCtx = useContext(ProductsContext);

  const isMobile = useMediaQuery('(max-width: 600px)');

  const [filters, setFilters] = useState(null);
  const [sorting, setSorting] = useState(defaultSorting);
  const [layout, setLayout] = useState(layoutTypesNames.GRID_DEFAULT);

  useEffect(() => {
    // log('(useEffect) Mounting Products', 'lightblue');
    productsCtx.fetchProducts();

    const { search } = props.location;

    if (!search) {
      setSorting(defaultSorting);
      return setFilters(defaultFilters);
    }

    const decoded = decodeSearchParams(search);

    const { sorting: newSorting } = { ...decoded };

    delete decoded.sorting;

    const newFilters = constructFiltersFromSearch(decoded, defaultFilters);

    setFilters(newFilters);
    setSorting(newSorting || '');
  }, []);

  useEffect(() => {
    if (!filters) {
      return;
    }

    props.history.push({
      pathname: '/',
      search: generateSearchParams(filters, sorting),
    });
  }, [filters, sorting, layout]);

  const handleSortingChange = (value) => setSorting(value);

  // let timeout = null;

  const handleFilterChange = (key, val) => {
    // clearTimeout(timeout);

    // timeout = setTimeout(() => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value: val,
      },
    }));
    // }, 500);
  };

  const handleAddToCart = (product) => cartCtx.addItem(product);

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Products
      </Typography>

      <Grid container spacing={32}>
        <Grid item xs={12} md={3}>
          <Grid
            container
            spacing={32}
            style={{ ...(!isMobile ? { position: 'sticky', top: 70 } : {}) }}
          >
            <Grid item xs={12}>
              <ProductsSorting
                sorting={sorting}
                defaultExpanded={!isMobile}
                onSortingChange={handleSortingChange}
              />
            </Grid>

            <Grid item xs={12}>
              {filters && (
                <ProductsFilters
                  filters={filters}
                  defaultExpanded={!isMobile}
                  onFilterChange={handleFilterChange}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9}>
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <ProductsLayoutSettings
                layout={layout}
                onChangeLayout={(type) => setLayout(type)}
              />
            </Grid>

            {!productsCtx.products.length ? (
              <Grid container alignItems="center" direction="column">
                <Grid item style={{ marginTop: 40 }}>
                  <CircularProgress />
                </Grid>

                <Grid item style={{ marginTop: 40 }}>
                  <Typography variant="display1">
                    <q>Patience is a virtue</q>
                  </Typography>

                  <Typography variant="caption" style={{ textAlign: 'right' }}>
                    (the server runs on Heroku free tier)
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {productsCtx.products.length &&
                      productsCtx.products.filter((product) =>
                        filters ? applyFilters(product, filters) : true
                      ).length}{' '}
                    products found
                  </Typography>
                </Grid>

                {productsCtx.products.length &&
                  productsCtx.products
                    .filter((product) =>
                      filters ? applyFilters(product, filters) : true
                    )
                    .sort((a, b) =>
                      sorting ? applySorting(a, b, sorting) : true
                    )
                    .map((product) => (
                      <Grid key={product.id} item {...layoutTypes[layout]}>
                        <ProductCard
                          product={product}
                          layout={layout}
                          onAddToCart={handleAddToCart}
                        />
                      </Grid>
                    ))}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Products.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Products);
