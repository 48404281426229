import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { AuthDispatchContext, AuthStateContext } from '../store/authStore';
import { CartContext } from '../store/cartStore';
import { routes } from '../config/routes';
import CartDrawer from './CartDrawer';
import MenuDrawer from './MenuDrawer';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
    transition: '300ms',
  },
  homeLink: {
    color: 'white',
    textDecoration: 'none',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const orderedRoutes = [...routes].reverse().filter((route) => !!route.linkText);

const Header = ({ classes }) => {
  const cartCtx = useContext(CartContext);
  const authDispatchCtx = useContext(AuthDispatchContext);
  const authStateCtx = useContext(AuthStateContext);

  const isMobile = useMediaQuery('(max-width: 700px)');

  const { user } = authStateCtx;

  const userRoutes = orderedRoutes.filter(
    ({ privateRoute }) => !privateRoute || (privateRoute && !!user)
  );

  const closeCartDrawer = () => cartCtx.toggleCartDrawer(false);

  return (
    <AppBar position="fixed">
      <Toolbar>
        {isMobile && (
          <MenuDrawer
            routes={userRoutes}
            className={classes.menuDrawer}
            onNavigate={closeCartDrawer}
          />
        )}

        <Typography variant="h5" className={classes.grow}>
          <Link to="/" className={classes.homeLink} onClick={closeCartDrawer}>
            Games Store
          </Link>
        </Typography>

        {!isMobile &&
          userRoutes.map(({ path, linkText }) => (
            <Button
              key={path}
              color="inherit"
              component={Link}
              to={path}
              onClick={closeCartDrawer}
            >
              {linkText}
            </Button>
          ))}

        {!isMobile ? (
          user ? (
            <Button color="inherit" onClick={authDispatchCtx.logout}>
              Logout
            </Button>
          ) : (
            <Button color="inherit" component={Link} to="/auth">
              Login
            </Button>
          )
        ) : null}

        <CartDrawer />

        <Avatar
          className={classes.avatar}
          component={Link}
          to="/auth"
          style={{ opacity: user ? 1 : 0.5 }}
        >
          {user ? <AccountCircleIcon /> : <PersonOutlineIcon />}
        </Avatar>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object,
};

const compareProps = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default withStyles(styles)(React.memo(Header, compareProps));
