import { Link } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';

import { AuthDispatchContext, AuthStateContext } from '../store/authStore';

const styles = (theme) => ({
  menuDrawer: {
    zIndex: theme.zIndex.appBar - 100,
  },
  menuDrawerContent: {
    paddingTop: theme.mixins.escapeFromAppBar,
  },
});

const MenuDrawer = ({ routes, classes, onNavigate }) => {
  const authStateCtx = useContext(AuthStateContext);
  const authDispatchCtx = useContext(AuthDispatchContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { user } = authStateCtx;

  const onClose = () => setIsMenuOpen(false);

  return (
    <>
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
        onClick={() => setIsMenuOpen((isOpen) => !isOpen)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        open={isMenuOpen}
        onClose={onClose}
        className={classes.menuDrawer}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={onClose}
          onKeyDown={onClose}
          className={classes.menuDrawerContent}
        >
          <div>
            <List>
              {routes.map(({ path, linkText }) => (
                <ListItem
                  button
                  key={path}
                  component={Link}
                  to={path}
                  onClick={onNavigate}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={linkText} />
                </ListItem>
              ))}
            </List>

            <Divider />

            {user ? (
              <ListItem button onClick={authDispatchCtx.logout}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            ) : (
              <ListItem button component={Link} to="/auth" onClick={onNavigate}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

MenuDrawer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default withStyles(styles)(MenuDrawer);
