import React from 'react';
import PropTypes from 'prop-types';
import { StateInspector } from 'reinspect';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import { AuthStateContext } from './store/authStore';
import { routes } from './config/routes';

import Store from './store/index';

const escapeFromAppBar = 80;

const theme = createMuiTheme({
  mixins: {
    escapeFromAppBar,
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiDialog: {
      container: {
        marginTop: escapeFromAppBar,
      },
    },
  },
});

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    marginTop: 80,
    padding: theme.spacing.unit * 3,
  },
});

const AppWrapper = ({ children }) => (
  <Router>
    <MuiThemeProvider theme={theme}>
      <StateInspector name="Global State">
        <Store>{children}</Store>
      </StateInspector>
    </MuiThemeProvider>
  </Router>
);

const renderRoutes = () =>
  routes.map(({ path, component, privateRoute }) =>
    privateRoute ? (
      <PrivateRoute key={path} path={path} component={component} />
    ) : (
      <Route key={path} path={path} component={component} />
    )
  );

const AppContent = withStyles(styles)(({ classes }) => (
  <main className={classes.content}>
    <AuthStateContext.Consumer>
      {({ loading }) =>
        !loading && (
          <Switch>
            {renderRoutes()}

            <Redirect to="/" />
          </Switch>
        )
      }
    </AuthStateContext.Consumer>
  </main>
));

const App = () => (
  <div className="App">
    <AppWrapper>
      <CssBaseline />

      <Header />

      <AppContent />
    </AppWrapper>
  </div>
);

App.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(App);
