import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';

const CustomTableCell = withStyles({
  paddingDense: {
    paddingLeft: 12,
    paddingRight: 12,
  },
})(TableCell);

const styles = {
  root: {
    width: '100%',
    maxHeight: 'calc(100vh - 230px)',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  tableRow: {
    cursor: 'pointer',
  },
};

const headers = [
  // 'id',
  'status',
  // 'firstName',
  // 'lastName',
  'price',
  // 'street',
  // 'number',
  // 'postalCode',
  // 'city',
  // 'country',
];

const OrdersTable = ({
  classes,
  data,
  onSelectOrder,
  onEditOrder,
  onDeleteOrder,
}) => {
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell align="center" padding="dense">
              #
            </CustomTableCell>

            {headers.map((header) => (
              <CustomTableCell
                align="left"
                key={header}
                style={{ textTransform: 'capitalize' }}
                padding="dense"
              >
                {header}
              </CustomTableCell>
            ))}

            <CustomTableCell align="right" padding="dense">
              Actions
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length && (
            <TableRow>
              <CustomTableCell colSpan={headers.length} align="left">
                No orders found
              </CustomTableCell>
            </TableRow>
          )}
          {data.map((order, index) => (
            <TableRow
              key={order.id}
              className={classes.tableRow}
              onClick={() => onSelectOrder(order.id)}
              hover
            >
              <CustomTableCell
                component="th"
                scope="row"
                padding="dense"
                align="center"
              >
                {index + 1}.
              </CustomTableCell>

              {headers.map((key) => (
                <CustomTableCell key={key} padding="dense">
                  {key === 'price' && '$'}
                  {order[key]}
                </CustomTableCell>
              ))}

              <CustomTableCell align="right" padding="dense">
                <Button
                  color="primary"
                  size="small"
                  onClick={() => onEditOrder(order.id)}
                  className={classes.button}
                >
                  Edit
                </Button>

                <Button
                  color="secondary"
                  size="small"
                  onClick={() => onDeleteOrder(order.id)}
                  className={classes.button}
                >
                  Delete
                </Button>
              </CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

OrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectOrder: PropTypes.func.isRequired,
  onEditOrder: PropTypes.func.isRequired,
  onDeleteOrder: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(OrdersTable);
