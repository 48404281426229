import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthStateContext } from '../store/authStore';

const PrivateRoute = ({ ...props }) => {
  const { user } = useContext(AuthStateContext);

  return user ? <Route {...props} /> : <Redirect to="/auth" />;
};

export default PrivateRoute;
