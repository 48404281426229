export const applySorting = (a, b, sortingConfig) => {
  const [key, type] = sortingConfig.split('_');

  const valueA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
  const valueB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

  if (valueA < valueB) {
    return type === 'ASC' ? -1 : 1;
  }

  if (valueA > valueB) {
    return type === 'ASC' ? 1 : -1;
  }

  return 0;
};
