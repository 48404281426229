import React from 'react';

import AuthStore from './authStore';
import OrdersStore from './ordersStore';
import ProductsStore from './productsStore';
import CartStore from './cartStore';

const Store = ({ children }) => (
  <AuthStore>
    <ProductsStore>
      <OrdersStore>
        <CartStore>{children}</CartStore>
      </OrdersStore>
    </ProductsStore>
  </AuthStore>
);

export default Store;
