import axios from 'axios';

const setAxiosHeaders = token =>
  (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`);

export const saveToken = user => {
  sessionStorage.setItem('user', JSON.stringify(user));
  setAxiosHeaders(user && user.token);
};

export const getToken = () => {
  const userString = sessionStorage.getItem('user');
  return JSON.parse(userString);
};
