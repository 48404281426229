import React, { useEffect } from 'react';
import { useReducer } from 'reinspect';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import to from 'await-to-js';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { saveToken, getToken } from '../utils/token';
import { USERS_ENDPOINT } from '../config/api';
import { LOGIN, LOGOUT, SET_ERROR, CLEAR_ERROR } from '../actions/auth';
import ErrorDialog from '../components/ErrorDialog';
// import ContextProvider from '../hoc/ContextProvider';

const initialState = {
  user: null,
  loading: true,
  errorInfo: null,
  counter: 0,
};

// export const AuthContext = React.createContext({
//   ...initialState,
//   login: () => {},
//   logout: () => {},
//   register: () => {},
//   handleError: () => {},
//   increment: () => {},
// });

export const AuthStateContext = React.createContext({
  ...initialState,
});

export const AuthDispatchContext = React.createContext({
  login: () => {},
  logout: () => {},
  register: () => {},
  handleError: () => {},
  increment: () => {},
});

// eslint-disable-next-line react/display-name
export const withAuth = Component => props => (
  <AuthStateContext.Consumer>
    {({ user, loading }) => (
      <Component {...props} user={user} loading={loading} />
    )}
  </AuthStateContext.Consumer>
);

// TODO Reset Cart when logging out

const reducer = (state, action) => {
  switch (action.type) {
  case LOGIN:
    saveToken(action.payload);
    return { ...state, user: action.payload, loading: false };

  case LOGOUT:
    saveToken(null);
    return { ...state, user: null, loading: false };

  case SET_ERROR:
    return { ...state, errorInfo: action.payload };

  case CLEAR_ERROR:
    return { ...state, errorInfo: null };

  case 'INCREMENT': {
    return { ...state, counter: state.counter + 1 };
  }

  default:
    return state;
  }
};

const AuthStore = props => {
  const [state, dispatch] = useReducer(reducer, initialState, 'Auth');

  useEffect(() => {
    const user = getToken();

    dispatch({ type: LOGIN, payload: user });
  }, []);

  const login = async ({ email, password }) => {
    const { history, location } = props;

    const [error, userData] = await to(
      axios
        .post(`${USERS_ENDPOINT}/login`, {
          email,
          password,
        })
        .then(res => res.data)
    );

    if (error) {
      console.error('Login failed.');
      return get(error, 'response.data') || 'Network Error';
    }

    const { token, user } = userData;
    const userWithToken = { ...user, token };

    dispatch({ type: LOGIN, payload: userWithToken });

    location.pathname === '/auth' && history.push('/');
  };

  const logout = () => {
    dispatch({ type: LOGOUT });

    // props.history.push('/auth');
  };

  const register = async ({ email, password, name }) => {
    const { history } = props;

    const [error, userData] = await to(
      axios
        .post(`${USERS_ENDPOINT}`, {
          email,
          password,
          name,
        })
        .then(res => res.data)
    );

    if (error) {
      console.error('Register failed.');
      return get(error, 'response.data') || 'Network Error';
    }

    const { token, user } = userData;
    const userWithToken = { ...user, token };

    dispatch({ type: LOGIN, payload: userWithToken });

    history.push('/user');
  };

  const handleError = (error, action) => {
    dispatch({ type: SET_ERROR, payload: { error, action } });
  };

  const increment = () => dispatch({ type: 'INCREMENT' });

  const { errorInfo } = state;

  return (
    // <ContextProvider
    //   component={AuthContext.Provider}
    //   value={state}
    //   login={login}
    //   logout={logout}
    //   register={register}
    //   handleError={handleError}
    // >
    //   {props.children}
    //   <ErrorDialog
    //     errorInfo={errorInfo}
    //     hideDialog={() => dispatch({ type: CLEAR_ERROR })}
    //   />
    // </ContextProvider>
    // <AuthContext.Provider
    //   value={{
    //     user,
    //     loading,
    //     counter,
    //     login,
    //     logout,
    //     register,
    //     handleError,
    //     increment,
    //   }}
    // >
    //   {props.children}
    //   <ErrorDialog
    //     errorInfo={errorInfo}
    //     hideDialog={() => dispatch({ type: CLEAR_ERROR })}
    //   />
    // </AuthContext.Provider>
    <AuthDispatchContext.Provider
      value={{
        login,
        logout,
        register,
        handleError,
        increment,
      }}
    >
      <AuthStateContext.Provider value={state}>
        {props.children}
        <ErrorDialog
          errorInfo={errorInfo}
          hideDialog={() => dispatch({ type: CLEAR_ERROR })}
        />
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

AuthStore.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(
  React.memo(AuthStore, (prevProps, nextProps) => {
    return prevProps.location.pathname === nextProps.location.pathname;
  })
);
