import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { CartContext } from '../store/cartStore';
import { ProductsContext } from '../store/productsStore';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  imgWrapper: {
    maxWidth: 320,
  },
  thumbnail: {
    maxWidth: '100%',
  },
});

const ProductDetails = ({ classes, location, match }) => {
  const [product, setProduct] = useState(null);
  const cartCtx = useContext(CartContext);
  const productsCtx = useContext(ProductsContext);

  useEffect(() => {
    const { id } = match.params;

    const productFromStore =
      productsCtx.products.length &&
      productsCtx.products.find(p => p.id === id);

    if (productFromStore) {
      setProduct(productFromStore);
      return;
    }

    (async () => {
      const fetchedProduct = await productsCtx.fetchProduct(id);
      setProduct(fetchedProduct);
    })();
  }, []);

  return (
    product && (
      <Paper className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {product.name}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              System id: {product.id}
            </Typography>

            <Typography variant="h6" gutterBottom>
              Price: ${product.price}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className={classes.imgWrapper}>
              <img
                src={product.thumbnail}
                alt={product.name}
                className={classes.thumbnail}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography variant="body1" gutterBottom>
              {product.excerpt}
            </Typography>

            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => cartCtx.addItem(location.state)}
            >
              Add to cart
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  );
};

ProductDetails.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductDetails);
