import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';

import { AuthStateContext } from '../store/authStore';

const User = () => {
  const authStateCtx = useContext(AuthStateContext);

  const { user } = authStateCtx;

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Hello {user.name}
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your email is: {user.email}
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        Go ahead and order some stuff!
      </Typography>
    </div>
  );
};

export default User;
