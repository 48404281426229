import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const AuthForm = ({
  register = false,
  handleSubmit,
  handleSetRegister,
  error,
  classes,
  formStyles,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  return (
    <form
      className={classes.form}
      style={formStyles}
      onSubmit={e => {
        e.preventDefault();
        const data = { email, password };
        if (register) {
          data.name = name;
        }
        handleSubmit(data);
      }}
    >
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <FormControl margin="normal" error={!!error} required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl margin="normal" error={!!error} required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </FormControl>
        </Grid>

        <Collapse in={register}>
          <Grid item xs={12}>
            <FormControl margin="normal" error={!!error} required fullWidth>
              <InputLabel htmlFor="name">Name</InputLabel>
              <Input
                id="name"
                name="name"
                autoComplete="name"
                required={register}
                onChange={({ target: { value } }) => setName(value)}
              />
            </FormControl>
          </Grid>
        </Collapse>

        <Grid item xs={12}>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
      </Grid>

      <Grid container spacing={24}>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={() => handleSetRegister(!register)}
          >
            or {register ? 'sign in' : 'register'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

AuthForm.propTypes = {
  register: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleSetRegister: PropTypes.func.isRequired,
  error: PropTypes.string,
  classes: PropTypes.object.isRequired,
  formStyles: PropTypes.object,
};

export default withStyles(styles)(AuthForm);
