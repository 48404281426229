import React, { useContext } from 'react';
import { useReducer } from 'reinspect';
import axios from 'axios';
import to from 'await-to-js';

// import ContextProvider from '../hoc/ContextProvider';
import { AuthDispatchContext } from './authStore';
import { ORDERS_ENDPOINT } from '../config/api';
import { formatPrismaDate } from '../utils/date';
import {
  CREATE_ORDER,
  EDIT_ORDER,
  DELETE_ORDER,
  SET_ORDERS,
} from '../actions/orders';

const initialState = {
  orders: [],
};

export const OrdersContext = React.createContext({
  ...initialState,
  fetchOrders: () => {},
  createOrder: () => {},
  deleteOrder: () => {},
});

const reducer = (state, action) => {
  switch (action.type) {
  case CREATE_ORDER:
    return { ...state, orders: [...state.orders, action.payload] };

  case EDIT_ORDER: {
    const index = [...state.orders].findIndex(
      order => order.id === action.payload.id
    );
    const updatedOrders = [...state.orders];

    updatedOrders[index] = action.payload.editedOrder;

    return { ...state, orders: updatedOrders };
  }

  case DELETE_ORDER: {
    const updatedOrders = [...state.orders].filter(
      or => or.id !== action.payload.id
    );
    return { ...state, orders: updatedOrders };
  }

  case SET_ORDERS:
    return { ...state, orders: action.payload };

  default:
    return state;
  }
};

const OrdersStore = props => {
  const [state, dispatch] = useReducer(reducer, initialState, 'Orders');

  const authDispatchCtx = useContext(AuthDispatchContext);

  const fetchOrders = async () => {
    const [error, orders] = await to(
      axios.get(ORDERS_ENDPOINT).then(res => res.data)
    );

    if (error) {
      return authDispatchCtx.handleError(error, 'fetchOrders');
    }

    orders.map(order => (order.createdAt = formatPrismaDate(order.createdAt)));

    dispatch({ type: SET_ORDERS, payload: orders });
  };

  const createOrder = async data => {
    const [error, order] = await to(
      axios.post(ORDERS_ENDPOINT, data).then(res => res.data)
    );

    if (error) {
      return authDispatchCtx.handleError(error, 'createOrder');
    }

    dispatch({ type: CREATE_ORDER, payload: order });

    return order;
  };

  const editOrder = async (id, data) => {
    const [error, editedOrder] = await to(
      axios.put(ORDERS_ENDPOINT, { id, data }).then(res => res.data)
    );

    if (error) {
      return authDispatchCtx.handleError(error, 'editOrder');
    }

    editedOrder.createdAt = formatPrismaDate(editedOrder.createdAt);

    dispatch({ type: EDIT_ORDER, payload: { id, editedOrder } });
  };

  const deleteOrder = async id => {
    const [error, deletedOrder] = await to(
      axios.delete(ORDERS_ENDPOINT, { data: { id } }).then(res => res.data)
    );

    if (error) {
      return authDispatchCtx.handleError(error, 'deleteOrder');
    }

    dispatch({ type: DELETE_ORDER, payload: deletedOrder });
  };

  const { orders } = state;

  return (
    // <ContextProvider
    //   component={OrdersContext.Provider}
    //   value={state}
    //   fetchOrders={fetchOrders}
    //   createOrder={createOrder}
    //   editOrder={editOrder}
    //   deleteOrder={deleteOrder}
    // >
    //   {props.children}
    // </ContextProvider>
    <OrdersContext.Provider
      value={{
        orders,
        fetchOrders,
        createOrder,
        editOrder,
        deleteOrder,
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
};

export default OrdersStore;
