import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

const ErrorDialog = ({ hideDialog, errorInfo }) => {
  errorInfo && console.warn(`(handleError): ${errorInfo.action}`);

  return (
    <Dialog
      open={!!errorInfo}
      onClose={hideDialog}
      scroll="body"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">An Error occured</DialogTitle>

      <DialogContent>
        <Typography variant="h5" gutterBottom>
          {errorInfo && errorInfo.action}
        </Typography>

        <code>{JSON.stringify(get(errorInfo, 'error.response.data'))}</code>
      </DialogContent>

      <DialogActions>
        <Button onClick={hideDialog} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  hideDialog: PropTypes.func.isRequired,
  errorInfo: PropTypes.object,
};

export default ErrorDialog;
